import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: resolve => require(['@/views/Home.vue'], resolve),
    children: [
      {
        path: '/',
        name: 'order',
        component: resolve => require(['@/views/order/index.vue'], resolve),
      },
      {
        path: '/list',
        name: 'list',
        component: resolve => require(['@/views/order/list.vue'], resolve),
      },
      {
        path: '/importAllocate',
        name: 'importAllocate',
        component: resolve => require(['@/views/order/importAllocate.vue'], resolve),
      },
      {
        path: '/importAmendBom',
        name: 'importAmendBom',
        component: resolve => require(['@/views/order/importAmendBom.vue'], resolve),
      },
      {
        path: '/importComplete',
        name: 'importComplete',
        component: resolve => require(['@/views/order/importComplete.vue'], resolve),
      },
      {
        path: '/listImport',
        name: 'listImport',
        meta: { rule: 'aaoi' },
        component: resolve => require(['@/views/order/listImport.vue'], resolve),
      },
      {
        path: '/transfer',
        name: 'transfer',
        component: resolve => require(['@/views/order/transfer.vue'], resolve),
      },
      {
        path: '/logList',
        name: 'logList',
        component: resolve => require(['@/views/order/logList.vue'], resolve),
      },
      {
        path: '/api',
        name: 'api',
        component: resolve => require(['@/views/order/apipage.vue'], resolve),
      },
      {
        path: '/user',
        name: 'user',
        component: resolve => require(['@/views/order/user.vue'], resolve),
      },
      {
        path: '/rules',
        name: 'rules',
        component: resolve => require(['@/views/order/rules.vue'], resolve),
      },
      {
        path: '/setting',
        name: 'setting',
        component: resolve => require(['@/views/order/setting.vue'], resolve),
      },
      //调拨单
      {
        path: '/transferOrder',
        name: 'transferOrder',
        component: resolve => require(['@/views/transferOrder/index.vue'], resolve),
      },
      {
        path: '/formList',
        name: 'formList',
        component: resolve => require(['@/views/form/list.vue'], resolve),
      },
      {
        path: '/formImport',
        name: 'formImport',
        component: resolve => require(['@/views/form/import.vue'], resolve),
      },
      {
        path: '/digital',
        name: 'digital',
        component: resolve => require(['@/views/form/digital.vue'], resolve),
      },
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login/login.vue'], resolve),
  },
  {
    path: '/register',
    name: 'register',
    component: resolve => require(['@/views/login/register.vue'], resolve),
  },
  {
    path: '/password',
    name: 'password',
    component: resolve => require(['@/views/login/password.vue'], resolve),
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: resolve => require(['@/views/login/agreement.vue'], resolve),
  },
  {
    path: '/invite',//邀请用户
    name: 'invite',
    component: resolve => require(['@/views/login/invite.vue'], resolve),
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})

export default router
