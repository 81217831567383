import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import enUS from 'element-ui/lib/locale/lang/en'
import zhCN from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from './en'
import zhLocale from './zh'

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  }
}
// 纯英文的 暂未用到语言翻译
const i18n = new VueI18n({
  locale: "en",
  messages,
  silentTranslationWarn: true
})

export default i18n