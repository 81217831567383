

const user = {
  state: {
    api_id: '',
    roles: [],
  },
  mutations: {
    SET_API_ID: (state, data) => {
      state.api_id = data
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
  },
  actions: {
    change_API_ID({ commit }, data) {
      commit('SET_API_ID', data)
    },
    remove_API_ID({ commit }) {
      commit('SET_API_ID', '')
      commit('SET_ROLES', [])
    },
    set_ROLES({ commit }, data) {
      commit('SET_ROLES', data)
    }
  }
}
export default user