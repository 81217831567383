import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './utils/http'
Vue.use(http)
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import i18n from '@/i18n'
Vue.use(ElementUI, {
    size: 'small',
    i18n: (key, value) => i18n.t(key, value)
});
import './permission' // permission control
import directive from './directive' // directive
Vue.use(directive)
import store from './store'
Vue.config.productionTip = false
new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')